// Here you can add other styles
.container-lg {
  max-width: none;
}
.bg-light {
  --cui-bg-opacity: 0;
  //background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
}

/*.wrap table {display:none}
.wrap input {display:none}
.wrap input:checked + table {display:block}*/
