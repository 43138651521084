.vertical-center {
  vertical-align: middle !important;
}

.ErrorContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  background-color: #686767;
  border-radius: 8px;
  padding: 2px 8px;
}

.ErrorMsg {
  color: #fff;
  font-size: 13px;
}

.ErrorImgContainer {
  display: inline-flex;
  align-items: center;
}

.ErrorImg {
  margin-left: 3px;
  background: url(../assets/images/error.png);
  width: 15px;
  height: 15px;
}

.ErrorContainer::after {
  content: '';
  position: absolute;
  left: 75px;
  transform: translateX(-50%);
  bottom: -10px;
  border: 5px solid transparent;
  border-top-color: #686767;
}

.sever_code_wrap {
  display: flex;
  align-items: center;
}

.sever_code_wrap > div {
  border: 1px solid transparent;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: none;
  width: 110px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sever_code_wrap > div.active {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.sever_code_wrap > div:hover {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
}

.search_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.comment_container {
  margin-top: 20px;
}

.comment_container .button_box {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.comment_wrap {
  margin-top: 10px;
}

.comment_wrap .comment_wrapper {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.comment_wrap:last-child .comment_wrapper {
  border-bottom: none;
  padding-bottom: 0px;
}

.comment_wrap .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.comment_wrap .name {
  font-weight: bold;
  margin-right: 9px;
}

.comment_wrap .divider {
  color: #868181;
  opacity: 0.7;
  margin-right: 9px;
}

.comment_wrap .date {
  color: #868181;
  opacity: 0.7;
  font-size: 15px;
}

.comment_wrap .right .edit {
  margin-right: 5px;
  color: #868181;
  opacity: 0.7;
  font-size: 15px;
}

.comment_wrap .right .delete {
  color: #868181;
  opacity: 0.7;
  font-size: 15px;
}

.comment_wrap .save {
  color: #868181;
  opacity: 0.7;
  margin-left: 10px;
  font-size: 15px;
  margin-top: 5px;
}

.comment_wrap .bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 7px;
}

.comment_view_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #51515a;
  border-radius: 6px;
  opacity: 0.4;
  z-index: 9990;
}

.comment_view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  width: 23rem;
  height: 60%;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #ddd;
}

.comment_view .header_wrap {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.comment_view .body_wrap {
  padding: 0px 20px;
  max-height: 550px;
  overflow-y: auto;
}

@media (max-width: 1886px) {
  .comment_view {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 25rem;
    height: 60%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #ddd;
    z-index: 9999;
  }
}

.agency_comment_view_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #51515a;
  opacity: 0.4;
  z-index: 9990;
}

.agency_comment_view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  width: 26rem;
  height: 500px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #ddd;
}

.agency_comment_view .header_wrap {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.agency_comment_view .body_wrap {
  padding: 0px 20px;
  max-height: 430px;
  overflow-y: auto;
}

.connectInfo {
  color: rgb(79, 214, 79);
}

.disConnectInfo {
  color: rgb(218, 70, 70);
}
